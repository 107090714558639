<template>
    <div>
        <div class="text-center" v-if="loading">
            <loading-animation/>
            <h6 class="pl-2">Please wait while data is being loaded...</h6>
        </div>
        <div v-else class="row">
            <sales-card class="col-md-12" :sales="TodaySales"></sales-card>
            <top-product-list class="col-md-6" :topProducts="TopProducts"></top-product-list>
            <div class="col-md-12 mt-5">
                <tabs>
                    <tab title="Pending Orders" class="fl-eq">
                        <div class="page-content px-2 bg-2  bs-4 c-table">
                            <vue-table-card title="Pending Orders" class="title-primary"
                                            :fields='fields'
                                            :url="listUrl" :show-search-box="false" :show-pagination="true"
                                            :page-sizes="false" :per-page="10" :extra-params="pending"
                                            ref="table">

                                <template #actions="{rowData}">
                                    <div class="btn-group-tight">
                                        <btn color="primary" size="sm" text="Mark As Delivered"
                                             @click="DeliverystatusUpdate(rowData.id)"/>
                                        <btn color="danger" size="sm" text="Cancel" @click="CancelOrders(rowData.id)"/>
                                        <!--                                        <btn color="info" size="sm" text="View" @click="viewDetails(rowData)"/>-->
                                        <btn color="info" size="sm" text="View" @click="openModal(rowData,'pending')"/>
                                    </div>
                                </template>
                            </vue-table-card>
                        </div>
                    </tab>
                    <tab title="Delivered Orders" class="fl-eq">
                        <div class="page-content px-2 bg-2  bs-4 c-table">
                            <vue-table-card title="Delivered Orders" class="title-primary"
                                            :fields='fields'
                                            :url="listUrl" :show-search-box="false" :show-pagination="true"
                                            :page-sizes="[]" :per-page="10"
                                            :extra-params="ext"
                                            ref="table">

                                <template #actions="{rowData}">
                                    <btn color="info" size="sm" text="View" @click="openModal(rowData,'delivered')"/>

                                </template>

                            </vue-table-card>
                        </div>
                    </tab>
                </tabs>
            </div>
            <analytics-component-one class="col-md-6"></analytics-component-one>
            <analytics-component-two class="col-md-6"></analytics-component-two>
        </div>
        <modal title="Pending Details" ref="modal" width="50r">
            <div class="row" v-if="userDetails">
                <div class="col-md-5">
                    <div>
                        <h6 class="fl-x mb-2">
                            <span>Customer details</span></h6>
                        <p class="m-0">{{ userDetails.name }}</p>
                        <p class="m-0">{{ userDetails.mobile_number }}</p>
                    </div>
                    <div>
                        <h6 class="fl-x mb-2">
                            <span>Order details</span></h6>
                        <p class="m-0" v-if="orderItems.total_amount">
                            <span>&#8377;{{ orderItems.total_amount[0].total_amount }}</span>
                        </p>
                        <p v-if="orderItems.data">{{ getDate(orderItems.data[0].date) }}</p>

                    </div>
                    <div>
                        <h6 class="fl-x mb-2">
                            <span> Delivery address</span></h6>
                        <p class="fl-eqh-l" v-if="userDetails">
                            <span>{{ userAddress.address_line_1 }}</span>
                            <span>{{ userAddress.address_line_2 }}</span>
                            <span>{{ userAddress.landmark }}</span>
                            <span>{{ userAddress.pin_code }}</span>

                        </p>
                    </div>
                </div>
                <div class="col-md-7">
                    <h6 class="fl-x mb-2">
                        <span>Products</span></h6>
                    <div>
                        <simple-table :fields="fields1" :data="orderItems.data" class="text-center">
                            <template #multiple>
                                <i>*</i>
                            </template>
                            <template #equalTo>
                                <i>=</i>
                            </template>
                            <template>
                                <span>hai</span>
                            </template>
                        </simple-table>
                        <p v-if="orderItems.total_amount">
                            TOTAL:{{ orderItems.total_amount[0].total_amount }}
                        </p>

                        <!--                        <table class="table">-->
                        <!--                            <thead>-->
                        <!--                            <tr>-->
                        <!--                                <th>Items</th>-->
                        <!--                                <th>Quantity</th>-->
                        <!--                                <th>Price</th>-->
                        <!--                                <th>Total Price</th>-->
                        <!--                            </tr>-->
                        <!--                            <tr v-for="(i, orderItem) in orderItems.data" v-bind:key="orderItem">-->
                        <!--                                <td>{{ i.name }}</td>-->
                        <!--                                <td>{{ i.quantity }}</td>-->
                        <!--                                <td>{{ i.selling_price }}</td>-->
                        <!--                                <td>{{ i.total_price }}</td>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                                <td colspan="2"></td>-->
                        <!--                                <td>Total</td>-->
                        <!--                                <td colspan="3">{{ orderItems.total_amount[0].total_amount }}</td>-->
                        <!--                            </tr>-->
                        <!--                            </thead>-->
                        <!--                        </table>-->
                    </div>

                </div>

            </div>
        </modal>
    </div>

</template>

<script>
import SalesCard from '@/views/dashboard/dashboardComponents/salesCard';
import AnalyticsComponentOne from '@/views/dashboard/dashboardComponents/analyticsComponentOne';
import AnalyticsComponentTwo from '@/views/dashboard/dashboardComponents/analyticsComponentTwo';
import axios from 'secure-axios';
import urls from '@/data/urls';
import TopProductList from './dashboardComponents/topProductList';

export default {
    name       : 'dashboard',
    components : {
        TopProductList,
        AnalyticsComponentTwo,
        AnalyticsComponentOne,
        SalesCard
    },
    data () {
        return {
            ext : {
                date   : new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
                status : 'delivered'
            },
            pending       : { status : 'pending' },
            id            : '',
            status        : 'pending',
            dataLoading   : 'false',
            listUrl       : urls.dashboard.list,
            TopProducts   : [],
            TodaySales    : {},
            orders        : [],
            orderItems    : [],
            userDetails   : [],
            userAddress   : [],
            statusOptions : [
                {
                    value : 'pending',
                    label : 'Pending'
                },
                {
                    value : 'delivered',

                    label : 'Delivered'
                }
            ],
            loading : false,
            fields  : [
                {
                    name  : 'id',
                    title : 'Order Id'
                },
                {
                    name  : 'name',
                    title : 'Name'
                },
                {
                    name  : 'total_quantity',
                    title : 'Quantity'
                },
                {
                    name  : 'total_amount',
                    title : 'Amount'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }

            ],
            fields1 : [
                {
                    name  : 'name',
                    title : 'Items'
                },
                {
                    name  : 'quantity',
                    title : 'Quantity'
                },
                {
                    name  : '__slot:multiple',
                    title : ''
                },
                {
                    name  : 'selling_price',
                    title : 'Price'
                },
                {
                    name  : '__slot:equalTo',
                    title : ''
                },
                {
                    name  : 'total_price',
                    title : 'Total Price'
                }
            ]

        };
    },
    mounted () {
        this.loadSalesData();
        this.loadTopProductData();
    },
    methods : {
        async loadSalesData () {
            this.loading = true;
            const response = await axios.get(urls.dashboard.todaySalesData);
            console.log('response', response.data);
            this.TodaySales = response.data;
            this.loading = false;
        },
        async loadTopProductData () {
            this.loading = true;
            const response = await axios.get(urls.dashboard.TopProducts);
            console.log('top products', response.data.data);
            this.TopProducts = response.data.data;
            this.loading = false;
        },
        async DeliverystatusUpdate (orderid) {
            const that = this;
            const response = await axios.form(urls.orders.status, {
                order_id : orderid,
                status   : 'delivered'
            });
            this.loading = false;
            const json = response.data;
            console.log('json', json);
            if (json.success === true) {
                that.$notify('Delivery Status Updated Successfully', 'Success', {
                    type : 'success'
                });
                this.$refs.table.refreshTable();
            }
        },
        async CancelOrders (orderid) {
            this.loading = true;
            const response = await axios.form(urls.orders.cancel, {
                order_id : orderid
            });
            this.loading = false;
            const json = response.data;
            console.log('json', json);
            if (json.success === true) {
                this.$notify('Cancel order successfully', 'Success', {
                    type : 'success'
                });
                this.$emit('update');
                this.loading = false;
            }
        },
        async openModal (item, status) {
            this.loading = false;
            const response = await axios.get(urls.orders.orderList, {
                params : {
                    order_id   : item.id,
                    user_id    : item.user_id,
                    address_id : item.address_id,
                    status     : status
                }
            });
            if (response) {
                this.orders = response.data.data;
                this.orderItems = response.data.orderItems;
                this.userDetails = this.orders.filter(el => el.id === item.id)[0].user_id;
                this.userAddress = this.orders.filter(el => el.id === item.id)[0].address_id;
            }
            this.loading = false;
            this.$refs.modal.show();
        },
        getDate (datetime) {
            const current = new Date(datetime);
            const longMonth = current.toLocaleString('en-us', { month : 'long' });
            const date = current.getDate() + ' ' + longMonth + ' ' + current.getFullYear();
            const time = current.getHours() + ':' + (current.getMinutes() < 10 ? '0' : '') + current.getMinutes();
            let time1 = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time1.length > 1) { // If time format correct
                time1 = time1.slice(1); // Remove full string match value
                time1[5] = +time1[0] < 12 ? 'am' : 'pm'; // Set AM/PM
                time1[3] = ' ';
                time1[0] = +time1[0] % 12 || 12; // Adjust hours
            }
            time1 = time1.join('');
            const dateTime = time1 + ', ' + date;
            return dateTime;
        }
        // viewDetails (item) {
        //     this.$router.push('/orders/' + item.id + '/' + item.user_id + '/' + item.address_id + '/');
        // }

    }
};

</script>

<style scoped>

</style>
