<template>
    <div class="row">

        <div class="col-md-3">
            <div class="card bs-4 text-center">
                <h6>Today Orders</h6>
                <span class="font-weight-bold"> {{ sales.TodayOrders }}</span>
            </div>

        </div>
        <div class="col-md-3">
            <div class="card bs-4 text-center">
                <h6>Pending Orders</h6>
                <span class="font-weight-bold">{{ sales.PendingOrders }}</span>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card bs-4 text-center">
                <h6>Delivered Orders</h6>
                <span class="font-weight-bold">{{ sales.DeliveredOrders }}</span>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card bs-4 text-center">
                <h6>Today's Sales</h6>
                <span class="font-weight-bold">{{ sales.TodaySalesAmount }}</span>
            </div>
        </div>

    </div>

</template>

<script>

export default {
    name  : 'salesCard',
    props : {
        sales : {
            type     : Object,
            required : false
        }
    }

};
</script>

<style scoped>
</style>
