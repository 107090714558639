<template>
    <div class="home">
        <dashboard/>
    </div>
</template>

<script>
// @ is an alias to /src
import dashboard from '@/views/dashboard/dashboard';

export default {
    name       : 'Home',
    components : { dashboard }
};

</script>
