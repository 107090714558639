<template>
    <div>
        <div class="card bs-4 mt-5">
            <h6>Top Products</h6>
            <div v-if="topProducts.length>0">
                <div v-for="(top, index) in topProducts" v-bind:key="index"
                     class="fl-te-c py-2 p-2 bg-primary mb-2">
                    <span>{{ top.name }}</span>
                    <span>{{ top.total_sales }} units</span>
                </div>
            </div>
            <div v-else>
                <h6>No data</h6>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'topProductList',
    props : {
        topProducts : {
            type     : Array,
            required : false
        }
    }

};
</script>

<style scoped>

</style>
